import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>WHITE FILLINGS</h2>
      <h3>What are they?</h3>
      <p>A filling replaces part of a tooth that has been  lost because of decay or through accidental damage. You may hear the dentist talk about "composite", "glass ionomer" and "compomer" - these are different  types of white filling.</p>
      <h3>What will my dentist do?</h3>
      <p>Your dentist will:</p>
      <ul>
        <li>usually numb  the area around the tooth with an injection - but some small fillings may not need this</li>
        <li>remove any decay, together with any old filling material, using a small,  high-speed drill</li>
        <li>remove any weak part of the tooth which might break later</li>
        <li>wash and dry the tooth by blowing water and then air onto it (the dentist will be holding something which looks  like a water pistol)</li>
        <li>etch the surface  to be restored with a mild acid, to help the filling stick better</li>
        <li>coat the surface  that is to be restored with a bonding agent (which acts like cement) and then place the filling material -this is pushed into the cavity  that is to be filled and it is shaped  as required;</li>
        <li>harden the filling by pointing a bright light at it, inside  your mouth  (you will see the dentist and dental  nurse  protecting their eyes)- this is called  "curing"; and trim and polish  the filling as necessary.</li>
      </ul>
      <h3>What are the benefits?</h3>
      <p>Unlike silver (amalgam) fillings, white filling material sticks to teeth and can form edges, so it may be effectively used to repair front teeth that are chipped, broken, decayed or worn. It can also be used as a "veneer" to cover marks or discolouration that cleaning won't remove.</p>
      <p>White fillings are less noticeable than silver fillings, which may turn black in the mouth. White fillings come in a range of shades so they can be matched to the colour of your own teeth. A tooth needs less preparation for a white filling than for a silver filling.</p>
      <p>White fillings can sometimes be used in back teeth if there is not too much decay or damage, but they may be less durable than silver fillings under the wear and tear of chewing. White fillings are also more difficult to place in back teeth, as they need very dry conditions, which can be hard  to achieve right at the back  of your mouth.</p>
    </LightboxLayout>
  )
}

export default Page